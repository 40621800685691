import React, { useState } from 'react';
import { MenuIcon } from '../../utils/IconUtils';
import SideBar from '../../components/sideBar/SideBar';
import { Link } from 'react-router-dom';

import styles from './HomePage.module.scss';

const HomePage = () => {

    const [isOpenSideBar, setIsOpenSideBar] = useState(false);

    const closeFunction = () => {
        setIsOpenSideBar(false);
    }

    const menuItems = [
        {
            id: 0,
            title: "ANASAYFA",
            href: "/"
        },
        {
            id: 1,
            title: "KURUMSAL",
            href: "/",
        },
        {
            id: 2,
            title: "ÜRÜNLER",
            href: "/urunler"
        },
        {
            id: 3,
            title: "ÜRETİM",
            href: "/"
        },
        {
            id: 4,
            title: "İLETİŞİM",
            href: "/"
        },
    ] 

    return (
        <>
            <div className={styles.homePageContainer}>
                <div className={styles.menuContainer}>
                    <div className={styles.logoContainer}>
                        <h1>LOGO</h1>
                    </div>
                    <div onClick={() => setIsOpenSideBar(true)} className={styles.menuIconContainer}>
                        <MenuIcon style={{ width: "32px", height: "32px" }} color={"#fff"} />
                    </div>
                </div>
                <div className={styles.homePageMainContainer}>
                    <div className={`${styles.homeBoxContainer} ${styles.home1}`}>
                        <div className={styles.hoverOpacity}></div>
                        <div className={styles.contentContainer}>
                            <span>Lorem ipsum dolor sit</span>
                            <div className={styles.hoverTextContainer}>
                                <p className={styles.hoverText}>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur possimus sequi distinctio hic neque?
                                </p>
                            </div>
                            <div className={styles.line}></div>
                        </div>
                    </div>
                    <div className={`${styles.homeBoxContainer} ${styles.home2}`}>
                        <div className={styles.hoverOpacity}></div>
                        <div className={styles.contentContainer}>
                            <span>Lorem ipsum dolor sit</span>
                            <div className={styles.hoverTextContainer}>
                                <p className={styles.hoverText}>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur possimus sequi distinctio hic neque?
                                </p>
                            </div>
                            <div className={styles.line}></div>
                        </div>
                    </div>
                    <div className={`${styles.homeBoxContainer} ${styles.home3}`}>
                        <div className={styles.hoverOpacity}></div>
                        <div className={styles.contentContainer}>
                            <span>Lorem ipsum dolor sit</span>
                            <div className={styles.hoverTextContainer}>
                                <p className={styles.hoverText}>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur possimus sequi distinctio hic neque?
                                </p>
                            </div>
                            <div className={styles.line}></div>
                        </div>
                    </div>
                    <div className={`${styles.homeBoxContainer} ${styles.home4}`}>
                        <div className={styles.hoverOpacity}></div>
                        <div className={styles.contentContainer}>
                            <span>Lorem ipsum dolor sit</span>
                            <div className={styles.hoverTextContainer}>
                                <p className={styles.hoverText}>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur possimus sequi distinctio hic neque?
                                </p>
                            </div>
                            <div className={styles.line}></div>
                        </div>
                    </div>
                </div>
            </div>
            <SideBar 
                open={isOpenSideBar} 
                closeFunction={closeFunction}
                title={"MENÜLER"}
                align={"right"}
            >
                <div className={styles.sideBarContainer}>
                    <div className={styles.leftContainer}>
                        <h1>LOGO</h1>
                    </div>
                    <div className={styles.rightContainer}>
                        <div className={styles.rightMainContainer}>
                            {
                                menuItems.map((val) => (
                                    <div onClick={() => setIsOpenSideBar(false)} className={styles.menuItem}>
                                        <Link style={{ textDecoration: "none" }} className={styles.item} to={val.href}>
                                            {val.title}
                                        </Link>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </SideBar>
        </>
    )
}

export default HomePage