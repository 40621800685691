import React, { useState } from 'react';
import Menu from '../menu/Menu';
import { MenuIcon } from '../../utils/IconUtils';

import styles from './Header.module.scss';
import SideBar from '../sideBar/SideBar';
import { Link } from 'react-router-dom';

const Header = () => {

    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const menuItems = [
        {
            id: 0,
            title: "ANASAYFA",
            href: "/"
        },
        {
            id: 1,
            title: "KURUMSAL",
            href: "/",
        },
        {
            id: 2,
            title: "ÜRÜNLER",
            href: "/urunler"
        },
        {
            id: 3,
            title: "ÜRETİM",
            href: "/"
        },
        {
            id: 4,
            title: "İLETİŞİM",
            href: "/"
        },
    ] 

    const closeFunction = () => {
        setIsOpenMenu(false);
    }

    return (
        <>
            <div className={styles.headerContainer}>
                <div className={styles.mainContainer}>
                    <div style={{ cursor: "pointer" }}>
                        <span style={{ fontSize: "32px", fontWeight: 700 }}>LOGO</span>
                    </div>
                    <div className={styles.menuContainer}>
                        <Menu data={menuItems} />
                    </div>
                    <div onClick={() => setIsOpenMenu(true)} className={styles.menuIconContainer}>
                        <MenuIcon />
                    </div>
                </div>
            </div>
            <SideBar 
                open={isOpenMenu} 
                closeFunction={closeFunction}
                title={"MENÜLER"}
                align={"right"}
            >
                <div className={styles.sideMenuContainer}>
                    {
                        menuItems.map((val) => (
                            <div onClick={() => setIsOpenMenu(false)} key={val.id} className={styles.sideMenuItem}>
                                <Link style={{ textDecoration: "none" }} className={styles.item} to={val.href}>
                                    {val.title}
                                </Link>
                            </div>
                        ))
                    }
                    <div onClick={() => setIsOpenMenu(false)} className={styles.sideMenuItem}>
                        <Link className={styles.item} to={"#"}>
                            +90 555 555 55 55
                        </Link>
                    </div>
                    <div onClick={() => setIsOpenMenu(false)} className={styles.sideMenuItem}>
                        <Link className={styles.item} to={"mailto:lorem@loremlorem.com"}>
                            lorem@loremlorem.com
                        </Link>
                    </div>
                </div>
            </SideBar>
        </>
    )
}

export default Header