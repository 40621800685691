import React, { useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { CloseIcon } from '../../utils/IconUtils';

import styles from './SideBar.module.scss';

const SideBar = ({ open, closeFunction, title, align, children }) => {
    
    const sideBarRef = useRef(null);

    const sideBarSlide = {
        initial: {
            x: align === "right" ? "100%" : "-100%"
        },
        enter: {
            x: "0%",
            transition: {duration: 0.6, ease: [0.76, 0, 0.24, 1]},
            opacity: 1
        },
        exit: {
            x: align === "right" ? "100%" : "-100%",
            transition: {duration: 0.6, ease: [0.76, 0, 0.24, 1]},
            opacity: 0
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sideBarRef.current && !sideBarRef.current.contains(event.target)) {
                closeFunction();
            }
        };

        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open, closeFunction]);

    return (
        <>
            <AnimatePresence mode='wait'>
                {
                    open
                    ?
                        <div 
                            className={styles.sideBarContainer}
                            style={{justifyContent: align === "right" ? "flex-end" : "flex-start"}} 
                        >
                            <motion.div 
                                ref={sideBarRef}
                                className={styles.sideBarMainContainer}
                                variants={sideBarSlide} 
                                animate="enter" 
                                exit="exit" 
                                initial="initial" 
                            >
                                <div className={styles.titleContainer}>
                                    <span className={styles.title}>
                                        
                                    </span>
                                    <div onClick={() => closeFunction()} className={styles.closeIconContainer}>
                                        <CloseIcon />
                                    </div>
                                </div>
                                <div>
                                    {children}
                                </div>
                            </motion.div>
                        </div>
                    :
                        null
                }
            </AnimatePresence>
        </>
    )
}

export default SideBar