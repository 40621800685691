import React from 'react';

import styles from './ProductionPage.module.scss';
import StatisticsSection from '../../components/statisticsSection/StatisticsSection';

const ProductionPage = () => {

    return (
        <div className={styles.institutionalPageContainer}>
            <div className={styles.headerContainer}>
                <div className={styles.headerMainContainer}>
                    <h1>Üretim</h1>
                </div>
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.contentMainContainer}>
                    <div className={styles.topContainer}>
                        <div style={{ width: "100%", height: "100%" }}>
                            <h1>ÜRETİM</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima nihil inventore fugit sit ea molestiae voluptatibus distinctio voluptates reiciendis sed. Tempora maiores consectetur rem? Doloribus, fuga incidunt. Ipsa, ratione ex?</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima nihil inventore fugit sit ea molestiae voluptatibus distinctio voluptates reiciendis sed. Tempora maiores consectetur rem? Doloribus, fuga incidunt. Ipsa, ratione ex?</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima nihil inventore fugit sit ea molestiae voluptatibus distinctio voluptates reiciendis sed. Tempora maiores consectetur rem? Doloribus, fuga incidunt. Ipsa, ratione ex?</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima nihil inventore fugit sit ea molestiae voluptatibus distinctio voluptates reiciendis sed. Tempora maiores consectetur rem? Doloribus, fuga incidunt. Ipsa, ratione ex?</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima nihil inventore fugit sit ea molestiae voluptatibus distinctio</p>
                        </div>
                        <div style={{ width: "100%", height: "100%" }}>
                            <img  style={{ width: "100%", height: "100%" }} src="https://picsum.photos/600/490" alt="" />
                        </div>
                    </div>
                    <div className={styles.bottomContainer}>
                        <div style={{ width: "100%", height: "100%" }}>
                            <h1>KAPASİTE VE PARKUR</h1>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi reiciendis amet exercitationem pariatur ab repellat maiores porro debitis, expedita et vitae nesciunt. Corrupti optio veritatis quod explicabo eius maxime? Modi?
                            Maxime saepe molestias sunt eaque vitae aspernatur! Necessitatibus vel ratione iusto maiores, molestiae, commodi voluptatem incidunt ipsam ullam temporibus quisquam sunt sit suscipit. Totam ullam dignissimos ad odio, expedita saepe.
                            Dicta quam quasi recusandae voluptates. Quam, maxime. Qui harum a, eveniet reiciendis ea quod eius repellendus enim non possimus id, eaque beatae distinctio atque doloribus doloremque et accusantium consequuntur optio.</p>
                        </div>
                        <div style={{ width: "100%", height: "100%" }}>
                            <h1>BELGELERİMİZ</h1>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi reiciendis amet exercitationem pariatur ab repellat maiores porro debitis, expedita et vitae nesciunt. Corrupti optio veritatis quod explicabo eius maxime? Modi?
                            Maxime saepe molestias sunt eaque vitae aspernatur! Necessitatibus vel ratione iusto maiores, molestiae, commodi voluptatem incidunt ipsam ullam temporibus quisquam sunt sit suscipit. Totam ullam dignissimos ad odio, expedita saepe.
                            Dicta quam quasi recusandae voluptates. Quam, maxime. Qui harum a, eveniet reiciendis ea quod eius repellendus enim non possimus id, eaque beatae distinctio atque doloribus doloremque et accusantium consequuntur optio.</p>
                        </div>
                    </div>

                </div>
            </div>
            <StatisticsSection />
        </div>
    )
}

export default ProductionPage;