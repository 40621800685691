import React from 'react';

import styles from './ContactPage.module.scss';
import { LocationIcon } from '../../utils/IconUtils';

const ContactPage = () => {
    return (
        <div className={styles.contactPageContainer}>
            <div className={styles.headerContainer}>
                <div className={styles.headerMainContainer}>
                    <h1>Kurumsal</h1>
                </div>
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.contentMainContainer}>
                    <div className={styles.infoContainer}>
                        <div className={styles.infoTitleContainer}>
                            <h1>İletişim Bilgilerimiz</h1>
                        </div>
                        <div className={styles.infoLineContainer}>
                            <div className={styles.iconContainer}>
                                <LocationIcon color={"#c1d100"} style={{ width: "80px", height: "80px" }} />
                            </div>
                            <div className={styles.infoTexts}>
                                <h2>Adres</h2>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ratione totam repellat, fugiat, modi a aliquam assumenda odit minus officia accusantium!</p>
                            </div>
                        </div>
                        <div className={styles.infoLineContainer}>
                            <div className={styles.iconContainer}>
                                <LocationIcon color={"#c1d100"} style={{ width: "80px", height: "80px" }} />
                            </div>
                            <div className={styles.infoTexts}>
                                <h2>Adres</h2>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ratione totam repellat, fugiat, modi a aliquam assumenda odit minus officia accusantium!</p>
                            </div>
                        </div>
                        <div className={styles.infoLineContainer}>
                            <div className={styles.iconContainer}>
                                <LocationIcon color={"#c1d100"} style={{ width: "80px", height: "80px" }} />
                            </div>
                            <div className={styles.infoTexts}>
                                <h2>Adres</h2>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ratione totam repellat, fugiat, modi a aliquam assumenda odit minus officia accusantium!</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.contactFormContainer}>
                        <div className={styles.formTitleContainer}>
                            <h1>Hızlı İletişim Formu</h1>
                        </div>
                        <div className={styles.inputContainer}>
                            <input type="text" placeholder='Adınız ve Soyadınız' />
                            <input type="text" placeholder='E-Mail Adresiniz' />
                        </div>
                        <div className={styles.inputContainer}>
                            <input type="text" placeholder='Konu' />
                        </div>
                        <div className={styles.inputContainer}>
                            <textarea id="w3review" name="w3review" rows="8" placeholder='Mesajınız' />
                        </div>
                        <div className={styles.buttonContainer}>
                            <button>
                                <span>Gönder</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d28705.42792930587!2d29.27238382714476!3d40.865587930400494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1str!2str!4v1725187046750!5m2!1str!2str" width="100%" height="500" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    )
}

export default ContactPage