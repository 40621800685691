import React, { useState } from 'react';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import image1 from '../../static/1.png'
import image2 from '../../static/2.jpg'
import image3 from '../../static/3.jpg'
import propertyImage from '../../static/product-property.png'
import productBPA from '../../static/product-bpa.jpg'

import styles from './ProductDetailPage.module.scss';

const ProductDetailPage = () => {

    const [activeImage, setActiveImage] = useState(1);

    const getImage = (count) => {
        switch (count) {
            case 1:
                return image1;
            case 2:
                return image2;
            case 3:
                return image3;
            default:
              return image1
        }
    }

    return (
        <div className={styles.productDetailPage}>
            <Breadcrumb />
            <div className={styles.productContainer}>
                <div className={styles.productMainContainer}>
                    <div className={styles.leftContainer}>
                        <div onClick={() => setActiveImage(1)} className={activeImage === 1 ? styles.activeLeftImageContainer : styles.leftImageContainer}>
                            <img style={{ width: "100%", height: "100%" }} src={image1} alt="" />
                        </div>
                        <div onClick={() => setActiveImage(2)} className={activeImage === 2 ? styles.activeLeftImageContainer : styles.leftImageContainer}>
                            <img style={{ width: "100%", height: "100%" }} src={image2} alt="" />
                        </div>
                        <div onClick={() => setActiveImage(3)} className={activeImage === 3 ? styles.activeLeftImageContainer : styles.leftImageContainer}>
                            <img style={{ width: "100%", height: "100%" }} src={image3} alt="" />
                        </div>
                    </div>
                    <div className={styles.mainContainer}>
                        <div className={styles.mainImageContainer}>
                            <img style={{ width: "100%", height: "100%" }} src={getImage(activeImage)} alt="" />
                        </div>
                    </div>
                    <div className={styles.rightContainer}>
                        <div className={styles.properyContainer}>
                            <h2>KC-102/ 1500ml - 50,7 oz</h2>
                            <h1>Ala Sürahi KC-102</h1>
                        </div>
                        <div className={styles.properyContainer}>
                            <img style={{ width: "100%", height: "100%" }} src={productBPA} alt="" />
                        </div>
                        <div className={styles.properyContainer}>
                            <div className={styles.properyItemCont}>
                                <img style={{ width: "40px", height: "40px" }} src={propertyImage} alt="" />
                                <div className={styles.textContainer}>
                                    <h3>KOLİ İÇİ ADEDİ</h3>
                                    <h4>12</h4>
                                </div>
                            </div>
                            <div className={styles.properyItemCont}>
                                <img style={{ width: "40px", height: "40px" }} src={propertyImage} alt="" />
                                <div className={styles.textContainer}>
                                    <h3>KOLİ HACMİ</h3>
                                    <h4>0,040 m³</h4>
                                </div>
                            </div>
                            <div className={styles.properyItemCont}>
                                <img style={{ width: "40px", height: "40px" }} src={propertyImage} alt="" />
                                <div className={styles.textContainer}>
                                    <h3>KOLİ AĞIRLIĞI</h3>
                                    <h4>9,40 kg</h4>
                                </div>
                            </div>
                            <div className={styles.properyItemCont}>
                                <img style={{ width: "40px", height: "40px" }} src={propertyImage} alt="" />
                                <div className={styles.textContainer}>
                                    <h3>KOLİ EBATI</h3>
                                    <h4>595x235x285 mm</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductDetailPage