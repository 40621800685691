import React from 'react';

import styles from './Footer.module.scss';
import { Link } from 'react-router-dom';
import { LocationIcon, MailIcon, PhoneIcon } from '../../utils/IconUtils';

const Footer = () => {
    
    return (
        <div className={styles.footerContainer}>
            <div className={styles.footerMainContainer}>
                <div className={styles.logoContainer}>
                    <span>LOGO</span>
                </div>
                <div className={styles.footerBoxContainer}>
                    <div className={styles.boxTitleContainer}>
                        <span>KURUMSAL</span>
                    </div>
                    <div className={styles.boxContentContainer}>
                        <ul>
                            <li>LOREM, IPSUM DOLOR</li>
                            <li>LOREM, IPSUM DOLOR</li>
                            <li>LOREM, IPSUM DOLOR</li>
                            <li>LOREM, IPSUM DOLOR</li>
                            <li>LOREM, IPSUM DOLOR</li>
                            <li>LOREM, IPSUM DOLOR</li>
                        </ul>
                    </div>
                </div>
                <div className={styles.footerBoxContainer}>
                    <div className={styles.boxTitleContainer}>
                        <span>KURUMSAL</span>
                    </div>
                    <div className={styles.boxContentContainer}>
                        <ul>
                            <li>LOREM, IPSUM DOLOR</li>
                            <li>LOREM, IPSUM DOLOR</li>
                            <li>LOREM, IPSUM DOLOR</li>
                            <li>LOREM, IPSUM DOLOR</li>
                            <li>LOREM, IPSUM DOLOR</li>
                            <li>LOREM, IPSUM DOLOR</li>
                        </ul>
                    </div>
                </div>
                <div className={styles.footerBoxContainer}>
                    <div className={styles.boxTitleContainer}>
                        <span>KURUMSAL</span>
                    </div>
                    <div className={styles.boxContentContainer}>
                        <div className={styles.contactItem}>
                            <div>
                                <LocationIcon style={{ width: "16px", height: "16px" }} />
                            </div>
                            <div style={{ display: "flex" }}>
                                <Link className={styles.item} to={"#"}>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                </Link>
                            </div>
                        </div>
                        <div style={{ marginTop: "12px" }} className={styles.contactItem}>
                            <div>
                                <PhoneIcon style={{ width: "16px", height: "16px" }} color={"#000000"} />
                            </div>
                            <div style={{ display: "flex" }}>
                                <Link className={styles.item} to={"#"}>
                                    +90 555 555 55 55
                                </Link>
                            </div>
                        </div>
                        <div style={{ marginTop: "12px" }} className={styles.contactItem}>
                            <div>
                                <MailIcon style={{ width: "16px", height: "16px" }} color={"#000000"} />
                            </div>
                            <div style={{ display: "flex" }}>
                                <Link className={styles.item} to={"mailto:lorem@loremlorem.com"}>
                                    lorem@loremlorem.com
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Footer;