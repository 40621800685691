import React from 'react';

import styles from './ProductsPage.module.scss';
import ProductBox from '../../components/productBox/ProductBox';

const ProductsPage = () => {

    const productBoxDatas = [
        { id: 0, title: "Mutfak Gereçleri" },
        { id: 1, title: "Mutfak Gereçleri" },
        { id: 2, title: "Mutfak Gereçleri" },
        { id: 3, title: "Mutfak Gereçleri" },
        { id: 4, title: "Mutfak Gereçleri" },
        { id: 5, title: "Mutfak Gereçleri" },
        { id: 6, title: "Mutfak Gereçleri" },
        { id: 7, title: "Mutfak Gereçleri" },
        { id: 8, title: "Mutfak Gereçleri" },
        { id: 9, title: "Mutfak Gereçleri" },
        { id: 10, title: "Mutfak Gereçleri" },
        { id: 11, title: "Mutfak Gereçleri" },
    ]

    return (
        <div className={styles.productsPageContainer}>
            <div className={styles.pageTitleContainer}>
                <h1>ÜRÜNLER</h1>
            </div>
            <div className={styles.productsContainer}>
                <div className={styles.productsMainContainer}>
                    { productBoxDatas.map(val => ( <ProductBox key={val.id} title={val.title} /> )) }
                </div>
            </div>
        </div>
    )
}

export default ProductsPage;