import React from 'react';
import { ArrowRightLongIcon } from '../../utils/IconUtils';
import { Link, useNavigate } from 'react-router-dom';

import styles from './ProductBox.module.scss';

const ProductBox = ({ title }) => {

    const navigate = useNavigate();

    return (
        <div onClick={() => navigate(`/urun-detay`)} className={styles.productBoxContainer}>
            <div className={styles.imageContainer}>
                <img src="https://picsum.photos/240/240" alt="" />
            </div>
            <div className={styles.contentContainer}>
                <span className={styles.title}>{`| ${title}`}</span>
                <Link className={styles.item} to={"#"}>
                    Detaylı İncele
                    {/* <ArrowRightLongIcon /> */}
                </Link>
            </div>
        </div>
    )
}

export default ProductBox