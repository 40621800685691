import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SANLayout from './SANLayout';
import HomePage from './pages/homePage/HomePage';
import ProductsPage from './pages/productsPage/ProductsPage';
import InstitutionalPage from './pages/institutionalPage/InstitutionalPage';
import ProductionPage from './pages/productionPage/ProductionPage';
import ContactPage from './pages/contactPage/ContactPage';
import ProductDetailPage from './pages/productDetailPage/ProductDetailPage';

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route element={<SANLayout/>}>
          <Route path="/urunler" element={<ProductsPage/>} />
          <Route path="/kurumsal" element={<InstitutionalPage/>} />
          <Route path="/uretim" element={<ProductionPage/>} />
          <Route path="/iletisim" element={<ContactPage/>} />
          <Route path="/urun-detay" element={<ProductDetailPage/>} />
        </Route>
      </Routes>
    </Router>
  )
}

export default AppRoutes;